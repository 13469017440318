// extracted by mini-css-extract-plugin
export var button = "Partnership-module--button--kFDaC";
export var button2 = "Partnership-module--button2--TfG0t";
export var buttonContainer = "Partnership-module--buttonContainer--vJYoc";
export var buttonContainerMain = "Partnership-module--buttonContainerMain--fqZ1W";
export var container = "Partnership-module--container--fBypN";
export var description = "Partnership-module--description--cJeAL";
export var image = "Partnership-module--image--rwHT5";
export var imageContainer = "Partnership-module--imageContainer--LJcCV";
export var sectionTitle = "Partnership-module--sectionTitle--De44e";
export var textContainer = "Partnership-module--textContainer--o1FB7";
export var title = "Partnership-module--title--IK12w";
export var wrapper = "Partnership-module--wrapper--QGW67";